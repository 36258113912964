import React, { Component } from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout/Layout";
import Typography from "../../components/Typography/Typography";
import * as classes from "./blogDetails.module.scss";

class Terms extends Component {
  componentDidMount() {
    this.assignIdsToHeaders();
  }

  slugify(text) {
    return text
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^a-zA-Z0-9-]/g, "");
  }

  assignIdsToHeaders() {
    const { data } = this.props;
    const termsData = data.prismicTermspage.data;
    const contentHtml = document.createElement("div");
    contentHtml.innerHTML = termsData.terms_content.html;

    contentHtml.querySelectorAll("h3").forEach((header) => {
      const slug = this.slugify(header.innerText);
      const matchingTableItem = termsData.table.find(
        (item) => this.slugify(item.table_row) === slug
      );

      if (matchingTableItem) {
        header.id = slug; // Assign id to the h3 element
      }
    });

    // Update the innerHTML of the container with the new HTML that includes IDs
    document.getElementById("terms-content").innerHTML = contentHtml.innerHTML;
  }

  renderTableOfContents(tableItems) {
    return (
      <ul
        style={{ listStyleType: "disc", marginLeft: "20px", marginTop: "20px", marginBottom: "20px", fontSize: "18px" }}
      >
        {tableItems.map((item, index) => (
          <li key={index}>
            <a
              href={`#${this.slugify(item.table_row)}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {item.table_row}
            </a>
          </li>
        ))}
      </ul>
    );
  }

  render() {
    const { data } = this.props;
    const termsData = data.prismicTermspage.data;

    return (
      <Layout>
        <div className={classes.root}>
          <Typography variant="h1" align="center">
            {termsData.title.text}
          </Typography>

          <Typography variant="h2" align="center" style={{ marginTop: '20px' }}>
            {termsData.table_title}
          </Typography>

          {this.renderTableOfContents(termsData.table)}

          {/* Content Sections */}
          <div
            id="terms-content"
            dangerouslySetInnerHTML={{ __html: termsData.terms_content.html }}
          />
        </div>
      </Layout>
    );
  }
}

export const query = graphql`
  query TermsQuery($id: String) {
    prismicTermspage(id: { eq: $id }) {
      data {
        title {
          text
        }
        table {
          table_row
        }
        terms_content {
          html
        }
      }
    }
  }
`;

export default Terms;
